import React from 'react'
import DesignSystem from '../../components/DesignSystem'

import Islands from './index'

const Oahu = () => {
  return (
    <Islands activeIsland="oahu">
      <DesignSystem.H1>Oahu (O'ahu)</DesignSystem.H1>
      <DesignSystem.P>blurb</DesignSystem.P>
    </Islands>
  )
}

export default Oahu
